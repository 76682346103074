import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment, {Moment} from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  email: string;
  comment: string;
  toggle1: boolean;
  toggle2: boolean;
  toggle3: boolean;
  ord_status: string;
  ord_date: string | Moment | Date;
  ord_template: string;
  templates: object[] | string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Emailnotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSendEmailNotifCallId: string = "";
  apiChngSettingsCallId: string ="";
  getEmailTemplatesApiCallId: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      email:"",
      comment:"",
      toggle1: true,
      toggle2: true,
      toggle3: true,
      ord_status:"",
      ord_date: moment(Date.now()),
      ord_template:"",
      templates:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.apiSendEmailNotifCallId) {
        if(responseJson?.errors.length==0){
          this.showAlert("Success","Email successfully sent");
        }
        else if(responseJson?.success.length==0){
          this.showAlert("Error","Failed, kindly check logs for further info.")
        }
        else if(responseJson?.success.length>0 && responseJson?.errors.length>0){
          this.showAlert("Message",`Email sent to ${responseJson?.success.length} users; failed for ${responseJson?.errors.length} users. Kindly refer logs.`)
        }
      }
      if (apiRequestCallId === this.apiChngSettingsCallId) {
       this.handleSettingsChange(responseJson);
      }
      if(apiRequestCallId === this.getEmailTemplatesApiCallId){      
        let arr1: string[]=[]
        responseJson?.templates?.data.map((item: {
          id: string;
          type: string;
          attributes: {
              name: string,
              subject: string,
              body: string
          }
      }) => {
          arr1.push(item?.attributes?.name)
        })
        this.setState({ templates: arr1 });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getEmailTemplates();
  }

  handleSettingsChange(responseJson: {error: string, message:string}){
    if(responseJson?.error){
      this.showAlert("Error",responseJson?.error)
    }
    else if(responseJson?.message){
      this.showAlert("Success",responseJson?.message)
    }
  }
  
  toggleType1=()=>{
    this.setState({toggle1: !this.state.toggle1}, ()=> this.changeSettings());
  }

  toggleType2=()=>{
    this.setState({toggle2: !this.state.toggle2}, ()=> this.changeSettings());
  }

  toggleType3=()=>{
    this.setState({toggle3: !this.state.toggle3}, ()=>this.changeSettings());
  }

  setStatus=(value: string)=>{
    this.setState({ ord_status: value });
  }

  setEmailValue = (text: string) => {
    this.setState({ email: text });
  };

  setCommentValue = (text: string) => {
    this.setState({ comment: text });
  };

  setDateValue = (text: string) => {
    this.setState({ ord_date: text });
  };

  setOrdTemplate=(text: string)=>{
    this.setState({ ord_template: text })
  }

  validateValues(){
    if(!this.state.email){
      return this.showAlert("Error","Email field can not be empty.")
    }
    else if(this.state.email!=="" && this.state.ord_template=="verification_email"){
      this.sendDetails(this.state.ord_template);
    }
    else if(this.state.ord_template=="payment_received"){
      this.checkPayRecived()
    }
    else if(this.state.ord_template!=="payment_received"){
      this.checkAllFields();
    }
  }

  checkPayRecived=()=>{
    if(this.state.comment==""){
      return this.showAlert("Error","Comment is required.")
    }
    else{
      this.sendDetails(this.state.ord_template);
    }
  }

  checkAllFields=()=>{
    if(this.state.ord_status==""){
      return this.showAlert("Error","Order status can not be empty.")
    }
    else if(this.state.ord_template==""){
      return this.showAlert("Error","Order template can not be empty.")
    }
    else{
      this.sendDetails(this.state.ord_template);
    }
  }

  checkIfDyanmic(){
    let temp = this.state.ord_template;
    if(temp!=="verification_email" && temp!=="payment_received" && temp!=="order_processed" && temp!=="order_placed" && temp!=="order_status_changed"){
      if(temp=="no_placeholder"){
        this.sendDetails("no_placeholder")
      }
      else{
        this.sendDetails("dynamic")
      }
    }
  }

  async sendDetails(type: string){
    const header = {
      "token": "guest",
      "Content-Type": 'application/json'
    };
    let data1;
    if(type=="verification_email"){
      data1={
        "type": "verification_email",
        "email" : this.state.email,
      }
    }
    else if(type=="dynamic"){
      data1={
        "type": this.state.ord_template,
        "email" : this.state.email,
          "dynamic_contents": {
                  "19": [
                      "max", 123123, "some comment"
                  ]
                  ,
                  "21": [
                      "lewis", 123453, "some comment"
                  ],
                  "22":[
                      "mark", 123454, "new comment", "some info"
                  ]
              }
      }
    }
    else if(type=="no_placeholder"){
      data1={
        "type": this.state.ord_template,
        "email" : this.state.email,
          "dynamic_contents": {
                  "21": []
              }
      }
    }
    else if(type!="payment_received"){
      data1={
        "type": type,
        "email" : this.state.email,
        "order_details": {
        "company_name": "Builder.ai",
        "order_number": 123455687,
        "date": this.state.ord_date,
        "price": 451684,
        "order_status" : this.state.ord_status,
        "address": "#123, behind ABCD school, Mumbai"
        }
      }
    }
    else if(type=="payment_received"){
      data1={
        "type": "payment_received",
        "email" : this.state.email,
        "payment_details": {
          "invoice_number": 65423156985,
          "comment": this.state.comment
        }
      }
    }
  
    const httpBody = data1;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendEmailNotifCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendEmailEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getEmailTemplates = () => {
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getEmailTemplatesApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmailTempEndpoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
      })
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  async changeSettings(){
    const header = {
      "token": "guest",
      "Content-Type": 'application/json'
    };
    
    const httpBody ={
      "settings": [
      { "name": "payment_received", "enabled": this.state.toggle2 },
      { "name": "order_updates", "enabled": this.state.toggle1 },
      { "name": "verification_email", "enabled": this.state.toggle3 }
    ]
  };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiChngSettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeSettingsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
