Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchApiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emailnotifications2";
exports.labelBodyText = "emailnotifications2 Body";

exports.btnExampleTitle = "Send email";
exports.btnAddProduct = "ADD PRODUCT";
exports.labelEmail = "E-mail";
exports.labelComments= "Comments";
exports.placeholderEmail = "Enter your email";
exports.placeholderComments = "Please add comments";
exports.headingNotif = "Notification Preferences :";
exports.labelOrdDetails = "Order Details :";
exports.labelOrdDate = "Order Date";
exports.labelOrdStatus = "Order Status"
exports.labelOrdTemplate= "Order Template";
exports.dropdownText = "Status"
exports.dropdown2Text = "Template";
exports.confirmText = "Confirm"
exports.cancelText = "Cancel"
exports.sendEmailEndpoint = "bx_block_email_notifications/send_email_notification"
exports.changeSettingsApiEndpoint = "/bx_block_email_notifications/settings"
exports.btnExampleTitle2 = "Send dynamic email";
exports.getEmailTempEndpoint = "/bx_block_email_notifications/email_templates";
// Customizable Area End